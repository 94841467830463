/**
 * @license
 * MyFonts Webfont Build ID 2965705, 2015-02-04T08:31:08-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed ../Fonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: NiftyScript-Regular by URW++
 * URL: http://www.myfonts.com/fonts/urw/nifty-script/regular/
 * Copyright: (URW)++,Copyright 2013 by (URW)++ Design &amp; Development
 * Licensed pageviews: 100,000
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=2965705
 *
 * Â© 2015 MyFonts Inc
*/

$font-path: 'assets/fonts/';

@font-face {
  font-family: 'NiftyScript-Regular';
  src: url($font-path + '2D40C9_4_0.woff') format("woff");
}
