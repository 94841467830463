@import "./fonts";
@import "~@bfl/components/theming/variables";
@import "~@bfl/components/theming/utils";
@import "~@bfl/components/theming/constants";

.blue-icon {
  color: $primary-default;
  font-size: 24px;
  vertical-align: bottom;
}

.overview-details-dialog {
  .description {
    @include bfc-font-size($section-title-font-size);
  }
}

bfc-single-page-layout.minimum-top-margin > bfc-base-page-layout.bfc-base-page-layout {
  // overwrite default spacing for link "back 2 overview"
  padding-top: $bfc-default-spacing * 2 !important;
}

bfc-single-page-layout > bfc-base-page-layout {
  flex-direction: column;
}

mat-horizontal-stepper.minimal-stepper {
  .mat-horizontal-stepper-header-container {
    .mat-step-icon,
    .mat-step-icon-not-touched {
      margin-right: 0 !important;
    }
    .mat-stepper-horizontal-line {
      margin-left: 0 !important;
      flex: none !important;
    }
    .mat-step-label {
      display: none !important;
    }
  }
  .button-row {
    margin-top: $bfc-default-spacing * 2;
  }
}

.bfc-form-field .mat-input-element[type=time]::after {
  display: none;
}